import React from 'react'
import { Link } from 'react-router-dom'
import finance from '../images/finance.png'
import health from '../images/healthcare.png'
import consumer from '../images/consumer.png'
import travel from '../images/travel.png'
import tele from '../images/tele.png'
import it from '../images/it.png'
import rd from '../images/r&d.png'
import Capp from '../images/Capp.png'
import Cnative from '../images/Cnative.png'
import business from '../images/business.png'
import analytics from '../images/analytics.png'
import ai from '../images/AI.png'
import digital from '../images/digital.png'
import cyber from '../images/cyber.png'
import bg from '../images/background.png'
import lg from '../images/lg.png'

const Nav = () => {
    return (
        <div>
            <div className="wrapper">
                <nav id='navbar' className="container">
                    <input type="checkbox" id="show-search" />
                    <input type="checkbox" id="show-menu" />
                    <label for="show-menu" className="menu-icon"><i className="fa fa-bars"></i></label>
                    <div className="cont md:ml-0 -ml-6">
                        <div className="logo">
                            <Link to="/"><img className="absolute" style={{ marginTop: "3px", marginLeft: '-10px !important' }} src={`${lg}`} width="62" alt="" />
                                <img className='rotate' src={`${bg}`} width="70" alt="" />  </Link>
                        </div>
                        <ul className="links">
                            <li>
                                <Link to="/" className="hvr">Home</Link>

                            </li>

                            <li><Link to="/insights" className="hvr insight" href="insights.html">Insights</Link>
                                <input type="checkbox" id="show-features" />
                                <label for="show-features">Insights</label>
                                <ul className="depthLink">
                                    <li>

                                        <div className="flex flex-row">
                                            <img className="py-3 ml-4 md:w-10 w-16 md:h-14 h-16 md:mt-0 mt-2 " src={`${health}`} />
                                            <Link to="/insights/healthcare-systems-and-services/">Healthcare Systems & Services </Link>
                                        </div></li>
                                        <li>
                                        <div className="flex flex-row">
                                            <img className="py-3 ml-4 md:w-10 w-16 md:h-14 h-16 md:mt-0 mt-2 " src={`${rd}`} />
                                            <Link to="/services">Product Engineering and R&D </Link></div>
                                    </li>
                                    <li>
                                        <div className="flex flex-row">
                                            <img className="py-3 ml-4 md:w-10 w-16 md:h-14 h-16 md:mt-0 mt-2 " src={`${finance}`} />
                                            <Link to="/insights/financial-services/">Financial Services </Link>
                                        </div></li>


                                    <li>

                                        <div className="flex flex-row">
                                            <img className="py-3 ml-4 md:w-10 w-16 md:h-14 h-16 md:mt-0 mt-2 " src={`${consumer}`} />
                                            <Link to="/insights/consumer-construction-and-retail/">Consumer, Construction & Retail </Link>
                                        </div></li>
                                    <li>

                                        <div className="flex flex-row">
                                            <img className="py-3 ml-4 md:w-10 w-16 md:h-14 h-16 md:mt-0 mt-2 " src={`${tele}`} />
                                            <Link to="/insights/telecom-and-technology/">Telecom & Technology </Link>
                                        </div></li>
                                    <li>
                                        <div className="flex flex-row">
                                            <img className="py-3 ml-4 md:w-10 w-16 md:h-14 h-16 md:mt-0 mt-2 " src={`${travel}`} />
                                            <Link to="/insights/travel-and-tourism/">Travel & Tourism </Link></div></li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/services" className="hvr service">Services</Link>
                                <input type="checkbox" id="show-services" />
                                <label for="show-services">Services</label>
                                <ul>
                                    <li>  <div className="flex flex-row">
                                        <img className="py-3 ml-4 md:w-10 w-16 md:h-14 h-16 md:mt-0 mt-2 " src={`${ai}`} />
                                        <Link to="/services/">AI & Future Engineering </Link></div></li>
                                    <li>  <div className="flex flex-row">
                                        <img className="py-3 ml-4 md:w-10 w-16 md:h-14 h-16 md:mt-0 mt-2 " src={`${analytics}`} />
                                        <Link to="/services">Data & Analytics Applications</Link></div></li>


                                    <li>  <div className="flex flex-row">
                                        <img className="py-3 ml-4 md:w-10 w-16 md:h-14 h-16 md:mt-0 mt-2 " src={`${digital}`} />
                                        <Link to="/services">Digital & Business Analytics Process </Link></div></li>

                                    <li>
                                        <div className="flex flex-row">
                                            <img className="py-3 ml-4 md:w-10 w-16 md:h-14 h-16 md:mt-0 mt-2 " src={`${it}`} />
                                            <Link to="/services">IT & IT Enabled Managed Applications </Link></div>
                                    </li>
                                  
                                    <li>  <div className="flex flex-row">
                                        <img className="py-3 ml-4 md:w-10 w-16 md:h-14 h-16 md:mt-0 mt-2 " src={`${Capp}`} />
                                        <Link to="/services">ERP & Cloud Applications </Link></div>
                                    </li>
                                    <li>  <div className="flex flex-row">
                                        <img className="py-3 ml-4 md:w-10 w-16 md:h-14 h-16 md:mt-0 mt-2 " src={`${cyber}`} />
                                        <Link to="/services">Managed Cyber Security </Link></div></li>

                                </ul>
                            </li>
                            <li>
                                <Link to="/careers" className="hvr">Careers</Link>

                            </li>
                            <li><Link to="/aboutus" className="hvr" href="about-us.html">About us</Link>

                            </li>
                            <li><Link to="/contactus" className="hvr" href="contact-us.html">Contact us</Link></li>
                        </ul>
                    </div>
                    <Link to="/search">
                        <label className="search-icon md:mr-0 mr-6"><i className="fa fa-search"></i></label>
                    </Link>

                </nav>
            </div>
        </div>

    )
}

export default Nav
