import React, { useState, useEffect } from 'react'
import sanityClient from '../client.js'
import { Link } from 'react-router-dom'
import Nav from './Nav'
import Footer from './Footer'
import ContentServicesInfo from './ContentServicesInfo'
import { useParams } from 'react-router-dom'
import Loader from './Loader.js'

const ContentServices = () => {
    const [servicesData, setServicesData] = useState(null)
    const { slug } = useParams()
    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "services" && slug.current == "${slug}" ]{
            title,
            slug,
            description,
           bannerImage{
            asset->{
                _id,
                url
            },
            alt
        },
        imagesContent,
        textContent,
      }`,
            )
            .then((data) => {
                // console.log("data : " + data[0])
                setServicesData(data[0])
            })
            .catch(console.error)
    }, [slug])

    if (!servicesData) return <><Loader /></>

    return (
        <div>
            <Nav />
            <ContentServicesInfo services={servicesData} />
            <Footer />
        </div>
    )
}

export default ContentServices
