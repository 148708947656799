import React from 'react'
import Nav from './Nav'
import Footer from './Footer'
import ContactusHero from './ContactusHero'
import ContactusContent from './ContactusContent'
import Services from './Services'
import Enquiry from './Enquiry'
import { SEO } from './Seo'
import UMap from './UMap'
import Testimonials from './Testimonials'
import ContactForm from './ContactForm'
const Home = () => {
    return (
        <div>
        <SEO title="Ubiqlogix | Contact-us" description="Ubiqlogix Private limited" />
            <Nav />
            <ContactusHero />
            <Services />
<ContactusContent />

<Enquiry />
<UMap />
            <Footer />
</div>
    )
}

export default Home
