import React, { Fragment, useState, useEffect } from 'react'

const CareersModal = ({ data }) => {
    const [showModal, setShowModal] = useState(false);
    return (

        <>
            <div data-aos="fade-up" class="flex flex-col">
                <div class="lg:w-full flex flex-col job py-12 sm:flex-row sm:items-center items-start ">
                    <div class="flex md:w-3/5 w-5/6 cpart flex-col ">
                        <h1 class="flex-grow job-title text-2xl  md:text-2xl mb-1  text-white">{data.title}</h1>
                        <h3 class="flex-grow location  text-lg mb-4 ">{data.location}</h3>
                        {/* <h3 class="text-xs  text-white flex-grow job-des">Straight Product launches with a systematic flow that gives you the highest return on investment in the online world Lorem ipsum dolor sit amet.</h3> */}
                    </div>
                    <div class="items-end ml-16 apply-b">
                        <button class="text-white contactus  mt-10 sm:mt-0" onClick={() => setShowModal(true)}>Apply Now</button>
                    </div>

                </div>

                <div class="flex mt-2 mb-8 justify-start">
                    <div class=" rounded-full   inline-flex" style={{ height: '1px', width: '960px', backgroundColor: '#ffffff' }}></div>
                </div>
            </div>

            {showModal ?
                <>
                    <div>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none "
                        >
                            <div className="relative  my-6 mt-20   mx-auto max-w-3xl min-w-x">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex text-x  text-black items-start  gap-5 justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                        <div className="flex flex-col mt-4 ml-6">
                                            <h3 className="md:text-2xl text-x  font-bold">
                                                {data.title}
                                            </h3>
                                            <h4 className="md:text-xl font-semibold">
                                                {data.location}
                                            </h4>

                                        </div>
                                        <button
                                            className="p-1  ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent text-black opacity-5 h-6 w-6 md:text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative ml-6 text-black p-6 flex-auto">
                                        <h4 className="md:text-xl ">
                                            {data.experience}
                                        </h4>

                                        <p className="my-4  text-xs     text-justify leading-relaxed" style={{ height: '160px', overflow: 'auto' }}>
                                            {data.description}
                                        </p>

                                        <p className="my-4 font-bold md:text-lg leading-relaxed">
                                            {data.contactEmail}
                                        </p>
                                        {/* <p className="my-4  text-xs    text-justify leading-relaxed" style={{ height: '160px', overflow: 'auto' }}>
                                            Send the Mail with Job Title as the Subject with your Resume and Portfolio in the mail.
                                        </p> */}
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="bg-emerald-500 text-black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"

                                        >
                                            <a href="mailto:careers@ubiqlogix.com">  Send Mail </a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </div>
                </> : null}


        </>

    )
}

export default CareersModal
