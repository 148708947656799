import React from 'react'
import enquiry from '../images/enquiry.jpg'
const Enquiry = () => {
    return (
        <div>
            <section id="connect" className=" py-12" style={{ backgroundColor: '#f2f2f2' }}>


                <div data-aos="fade-up" className=" text-gray-900 py-0 -mt-12  body-font" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginBottom: '0%' }}>
                    <div data-aos="fade-right" id="content" className="container showcase mx-auto -mb-20 mt-10" style={{ zIndex: '9999' }}>
                        <p className="text-4xl featured-content mb-2 text-black md:ml-20 ml-0 ">Contact Us</p>
                        <div className="flex mt-2 mb-12 justify-start ml-6  md:ml-20">
                            <div className="h-1 rounded-full w-36 inline-flex" style={{ backgroundColor: '#0057FF' }}></div>
                        </div>
                    </div>
                    <div className="page-content">
                        <div className="form-v10-content">
                            <form className="form-detail" action="https://formsubmit.co/sales@ubiqlogix.com" method="POST" id="myform">
                                <div className="form-left">

                                    <h6 className="ml-16 mb-0 mt-4 ">All fields are required</h6>

                                    <h2 className="text-lg">CHOOSE A SUBJECT </h2>

                                    <div className="form-group">
                                        <div className="form-row form-row-1">

                                            <label className='text-lg'>Services/Technology<br /> </label>
                                            <select name="Services/Technology">
                                                <option className="Services/Technology" value="title">--Select--</option>
                                                <option className="Services/Technology" value="AI & Future Engineering">AI & Future Engineering</option>
                                                <option className="Services/Technology" value="Data & Analytics Applications">Data & Analytics Applications</option>
                                                <option className="Services/Technology" value="Digital & Analytics Process Operations">Digital & Analytics Process Operations</option>
                                                <option className="Services/Technology" value="IT Application Managed">IT Application Managed</option>
                                                <option className="Services/Technology" value="Engineering and R&D">Engineering and R&D</option>
                                                <option className="Services/Technology" value="Cloud Application">Cloud Application
                                                </option>
                                                <option className="Services/Technology" value="Cloud navtive">Cloud Native</option>
                                                <option className="Services/Technology" value="Bussiness Process">Bussiness Process</option>
                                                <option className="Services/Technology" value="Managed Cyber Security">Managed Cyber Security</option>
                                            </select>
                                            <span className="select-btn mt-4">
                                                <i className="fa fa-angle-down"></i>
                                            </span>
                                        </div>



                                        <div className="form-row form-row-2">
                                            <label className='text-lg'>Interested in <br /> </label>
                                            <select name="Inquiry Category">
                                                <option className="Inquiry Category" value="title">*Interested in</option>
                                                <option className="Inquiry Category" value="Healthcare">Healthcare Systems & Services</option>
                                                <option className="Inquiry Category" value="Financial Services">Financial Services</option>
                                                <option className="Inquiry Category" value="Consumer, Construction & Retail">Consumer, Construction & Retail</option>
                                                <option className="Inquiry Category" value="Telecom & Technology">Telecom & Technology</option>
                                                <option className="Inquiry Category" value="Travel & Tourism">Travel & Tourism</option>
                                                <option className="Inquiry Category" value="Technical Staffing Request">Technical Staffing Request</option>
                                                <option className="Inquiry Category" value="Partnering with UbiqLogix">Partnering with UbiqLogix</option>
                                                
                                            </select>
                                            <span className="select-btn mt-3">
                                                <i className="fa fa-angle-down"></i>
                                            </span>

                                        </div>
                                    </div>
                                    <label className=' text-lg md:ml-16 ml-8 ' >Type your message</label>
                                    <div className="form-row">
                                        <textarea type="text" name="message" rows='5' placeholder="   Our message" required ></textarea>
                                    </div>
                                    <h2>Contact Details</h2>
                                    <div className="form-row text-lg">Full Name
                                        <input type="text" name="fullname" className="street" id="street" placeholder="Full Name" required />
                                    </div>

                                    <div className="form-group ">
                                        <div className="form-row text-lg form-row-1">Zip-code
                                            <input type="text" name="zip-code" className="zip" id="zip-code" placeholder="Zip Code" required />
                                        </div>
                                        <div className="form-row form-row-2 text-lg">State/City
                                            <input type="text" name='State/City' placeholder='State/City' required />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-row form-row-1  text-lg">Country
                                            <input type="text" name="country" placeholder="Country" required />

                                        </div>
                                        <div className="form-row form-row-2 text-lg">Phone Number(include)
                                            <input type="text" name="phone-number" className="phone" id="phone" placeholder="Phone Number" required />
                                        </div>
                                    </div>
                                    <div className="form-row text-lg">Email
                                        <input type="text" name="email" id="your_email" className="input-text" required pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}" placeholder="Your Email" />
                                    </div>

                                    <div className="form-checkbox">
                                        <label className="container text-xs"><h4 >☆ Thank you for your interest in UbiqLogix. Please complete the form, so that we can provide quick and efficient service. <br />☆ After entering your personal information and message in the fields below, please read our Privacy Statement and click “SEND” to send your message.</h4>

                                        </label>
                                    </div>
                                    <div className="form-row-last">
                                        <input type="submit" name="submit" className="register" value="Send" />
                                    </div>

                                </div>
                                <input type="hidden" name="_next" value="https://ubiqlogix.com/thanks" />
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Enquiry
