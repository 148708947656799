import React, { useState, useEffect } from 'react'
import sanityClient from '../client.js'
import Nav from './Nav'
import Footer from './Footer'
import Insight_hero from './Insight_hero'
import FeaturedContent from './FeaturedContent'
import { Link } from 'react-router-dom'
import HomeCarousal from './HomeCarousal.js'
import HomeC from './HomeC.js'
import { SEO } from './Seo'

const Home = () => {

    return (
        <div>
            <SEO title="Ubiqlogix | Insights" description="Ubiqlogix Private limited" />
            <Nav />
            <HomeC />
            <FeaturedContent />
            <Footer />
        </div>
    )
}

export default Home
