import React, { useState, useEffect } from 'react'
import sanityClient from '../client.js'
import { Link } from 'react-router-dom'
import Nav from './Nav'
import Footer from './Footer'
import ContentInfo from './ContentInfo'
import { useParams } from 'react-router-dom'
import Loader from './Loader'
const Content = () => {
    const [insightsData, setInsightsData] = useState(null)
    const { slug } = useParams()
    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "insights" && slug.current == "${slug}" ]{
            title,
            slug,
            description,
            mainImage{
               asset->{
                   _id,
                   url
               },
               alt
           },
           bannerImage{
            asset->{
                _id,
                url
            },
            alt
        },
        imagesContent,
        textContent,
      }`,
            )
            .then((data) => {
                // console.log("data :" + data[0])
                setInsightsData(data[0])
            })
            .catch(console.error)
    }, [slug])
    if (!insightsData) return <><Loader /></>
    return (
        <div>
            <Nav />
            <ContentInfo insights={insightsData} />
            <Footer />
        </div>
    )
}

export default Content
