import React from 'react'

import Nav from './Nav'
import Footer from './Footer'
import CareerContent from './CareerContent'
import { SEO } from './Seo'
const Home = () => {
    return (
        <div>
        
        <SEO title="Ubiqlogix | Careers" description="Ubiqlogix Private limited" />
            <Nav />
            <CareerContent />
            <Footer />
</div>
    )
}

export default Home
