import React from 'react'

import Nav from './Nav'
import Footer from './Footer'
import ServiceHero from './ServiceHero'
import Services from './Services'
import Enquiry from './Enquiry'
import HomeCarousal from './HomeCarousal'
import HomeC from './HomeC'
import ContactForm from './ContactForm'
import { SEO } from './Seo'
const Home = () => {
    return (
        <div>
            <Nav />
            <SEO title="Ubiqlogix | Services" description="Ubiqlogix Private limited" />
            <HomeC />
            <Services />
            <ContactForm />
            <Footer />
        </div>
    )
}

export default Home
