import React from 'react'
import ab1 from '../images/ab1.jpg'
import ab2 from '../images/ab2.jpg'
import ethic from '../images/ethic.jpg'
import ab from '../images/career.jpg'
import startupindia from '../images/startupindia.png'
import bg from '../images/contact.jpg'

import vide from '../images/Ubiqlogix.mp4'
import corevalues from '../images/core.png'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
const Whatwedo = () => {
    const ClickHandler = () => {
        ReactGA.event({
            category: 'Button',
            action: 'Clicked on Join-us button'
        })
    }


    return (
        <div>
            <div class="content-text  text-gray-900 body-font">
                <div class=" container mx-auto flex px-5 py-8 md:flex-row flex-col items-center ">
                    <div data-aos="fade-up" class="lg:max-w-lg -mb-10 md:mb-6 mt-20 lg:w-1/3 md:w-1/3 w-5/6">
                        <img class="object-cover rounded md:ml-0 -ml-8  mt-10 content-img" alt="hero" src={`${ab1}`} />
                    </div>
                    <div data-aos="fade-up" class="lg:flex-grow mt-20 md:w-1/2 lg:pl-24 md:pl-16  pl-0 flex flex-col md:items-start  md:text-left mb-10 md:mb-0 ">
                        <div className="mb-6">  <p class="text-3xl featured-content  " >Our Company</p> <div class="h-1 rounded-full w-16 md:ml-0 ml-0  inline-flex " style={{ backgroundColor: '#0057FF', }}></div> </div>
                        <p class="mb-8 text-lg leading-fit text-justify">UbiqLogix is an IT services and solutions company with leading capabilities in digital, cloud and security services. UbiqLogix is recognized as a startup by the Department for Promotion of Industry and Internal Trade, India. We offer Technology and Services, Strategy, Consulting, and Innovation. We pioneer solutions to address the entire breadth of client’s business needs, from strategy and design to operations, fueled by the fast evolving and innovative world of cloud, data, AI, connectivity, software, digital engineering and platforms and deliver unprecedented levels of performance and customer delight. We embrace an always-on learning agenda to create value and shared success to all our clients, people, shareholders, partners, and communities from our innovation ecosystem.</p>

                    </div>

                </div>

            </div>
            <div class="content-text  text-gray-900 body-font">
                <div class=" container mx-auto flex px-5 py-8 md:flex-row-reverse flex-col items-center ">
                    <div data-aos="fade-up" class="lg:max-w-lg ml-10 mb-6 mt-20 lg:w-1/3 md:w-1/3 w-5/6">
                        <img class="object-cover rounded md:mt-0 md:ml-0 -ml-12 -mt-24 content-img" alt="hero" src={`${ab}`} />
                    </div>
                    <div data-aos="fade-up" class="lg:flex-grow mt-0 md:w-1/2   flex flex-col md:items-start md:text-left mb-10 md:mb-0 ">
                        <div className="mb-6">  <p class="text-3xl featured-content " >Our Purpose </p> <div class="h-1 rounded-full w-16  md:ml-0 ml-0  inline-flex " style={{ backgroundColor: '#0057FF', }}></div> </div>
                        <p class="mb-8 text-lg leading-fit text-justify">

                            Ubiqlogix embarked on a programme of works to develop a strong digital presence within our customer community; internally referred to as project "Ubiq-X". The first phase of the strategy includes the development of modern web sites, online purchasing, customer self-service portals, information and learning hubs and other transactional support services. Following the successful delivery of baseline of core digital services, the program will shift focus onto digital demand generation solutions supported by marketing automation, social media as well as more traditional search engine optimization and marketing activities.</p>


                    </div>

                </div>

            </div>
            <div className="container items-center md:py-16 py-0 md:w-3/5 ml-auto  mr-0 md:mr-64">

                <img width='700' src={`${corevalues}`} alt="" />
            </div>
            <div class="text-gray-900 body-font">
                <div class="container mx-auto items-center flex sm:flex-row flex-col  py-16 -mb-10 text-left">
                    <div data-aos="fade-up" class="container  sm:ml-5 px-5   py-16 mx-auto">
                        <div class="lg:w-full px-6 py-10 rounded bg-white flex flex-col sm:flex-row sm:items-left items-start mx-auto" style={{ boxShadow: '0px 9px 9px 0px #dfdfdf', }}>
                            <div class="px-6"> <span class="lnr lnr-flag"></span> </div>
                            <div class="flex flex-col">
                                <h1 class="text-2xl mb-2 items-start">Our Mission</h1>
                                <h1 class="flex-grow  text-x font-medium title-font text-gray-900 text-justify">Our Mission is guided by the three principles, passion for invention, long term-thinking and operational excellence in Research & Development.  Our Mission is to build an alliance platform for mutual partnership and provide services, consulting across different domains involved in the alliance.</h1>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="800" class="container  sm:mr-5  px-5 py-10 mx-auto">
                        <div class="lg:w-full px-6 py-6 rounded  flex flex-col sm:flex-row sm:items-left items-start mx-auto" style={{ boxShadow: '0px 9px 9px 0px #dfdfdf', }}>
                            <div class="px-6"> <span class="lnr lnr-rocket"></span> </div>
                            <div class="flex flex-col">
                                <h1 class="text-2xl mb-2 items-start">Our Vision</h1>
                                <h1 class="flex-grow  text-x font-medium title-font text-gray-900 text-justify">The entrepreneurs that improve the way we live in a scalable way, will create long term profit as well as increase environmental and social sustainability. Our Vision is to be the most successful company in building a digital platform which can help entrepreneurs with global ambition partner with right Investor and create billion-dollar companies.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section>
                <div class=" mx-auto md:flex-col sm:py-16 mt-0  py-16 mb-6 lg:flex-row" style={{ backgroundImage: `url(${ethic}), linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0,.75))`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundBlendMode: 'overlay' }}>
                    <div class="container text-white  mx-auto " >

                        <div class="md:flex sm:text-center sm:object-center md:ml-0 ml-4 flex-wrap">
                            <div class="content-text  md:w-3/4 text-white body-font">
                                <div class=" container mx-auto flex px-5 py-8 md:flex-row-reverse flex-col items-center ">

                                    <div data-aos="fade-up" class="lg:flex-grow -mt-4 md:w-1/2   flex flex-col md:items-start md:text-left mb-10 md:mb-0 ">
                                        <div className="mb-6">  <p class="text-4xl featured-content m  " >Our Value & Ethics </p>  </div>
                                        <p class="mb-8 text-lg leading-fit"> We believe that a profitable and sustainable business cannot exist without sound ethics and integrity.</p>
                                        <p class="mb-8 text-lg leading-fit">We ensure our business adheres to the Business Ethics in providing the following :</p>
                                        <div className="md:ml-8 ml-0">
                                            <p class="mb-8 text-lg leading-fit"> A safe and inclusive work environment for our people.</p>
                                            <p class="mb-8 text-lg leading-fit">    A Mutual trust with our clients to achieve profitable and sustainable growth by delivering value and building long term relationship.</p>
                                            <p class="mb-8 text-lg leading-fit">    By maintaining the security and integrity of the client data, assets, thrid parties.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </section>

            <div class="container third-text mx-auto -mt-10 md:mb-0 mb-20 md:mt-0 flex px-5 py-8 md:flex-row-reverse flex-col items-center">
                <div data-aos="fade-up" class="lg:max-w-2/3 lg:w-full md:w-2/3 w-full">
                    <video width="auto" height="auto" autoPlay loop='true' >
                        <source src={`${vide}`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div data-aos="fade-up" class="lg:flex-grow  md:w-1/2 pr-6 pl-6  flex flex-col md:items-start md:text-left mb-16 md:mb-0 ">
                    <h1 class="title-font sm:text-4xl text-2xl mt-4 mb-7 font-medium " style={{ color: '#000080', }}>Interested in working with us?
                    </h1>
                    <Link to='/careers'> <div class="lbutton flex-end mt-0">  <button onClick={ClickHandler} class="inline-flex text-xl joinus">Join us</button> </div> </Link>

                </div>



            </div>

            <div class="content-text md:mt-10 mt-20 md:mb-10  text-gray-900 body-font">
                <div class=" container mx-auto flex px-5 py-8 md:flex-row-reverse flex-col items-center ">
                    <div data-aos="fade-up" class="lg:max-w-lg ml-0 md:ml-10 mb-6 mt-4 lg:w-3/5 md:w-3/5 w-5/6">
                        <img class="object-cover md:mt-0 md:ml-0  -mt-24 " alt="hero" src={`${startupindia}`} />
                    </div>
                    <div data-aos="fade-up" class="lg:flex-grow mt-0 md:w-1/2  flex flex-col md:items-start md:text-left mb-10 md:mb-0 ">
                        <div className="mb-6">  <p class="text-xl featured-content " >CONNECT WITH US ON STARTUP INDIA FORUM
                        </p> <div class="h-1 rounded-full w-16  md:ml-0 ml-0  inline-flex " style={{ backgroundColor: '#0057FF', }}></div> </div>
                        <p class="mb-8 text-xs leading-fit text-justify">

                            The Startup Hub India believes in nurturing talents at very early stages and the best way to connect with them is during their learning stages and Colleges / Universities provide an ideal platform to nourish these talents. The Startup Hub India is a platform for Entrepreneurs to connect with other Entrepreneurs, Advisers, Consultants, Mentors, Guides, Angel Investors, Incubators, Accelerators & Venture Capital Firms.</p>


                    </div>

                </div>

            </div>
        </div>
    )
}

export default Whatwedo
