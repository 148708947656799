import React from 'react'
import word from '../images/Wordmark.png'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <div className="">
            <div class="text-gray-900 " style={{ paddingTop: '10px' , backgroundColor: '#007DB7' }}>
                {/* <div class="container px-5 py-6 mx-auto flex flex-wrap">
            <img class="footer-logo" src={`${word}`} style={{width: '20%',}} alt="" />
          
        </div>*/}
                <div class="flex f1 flex-wrap mb-0 py-0">
                    <nav class="footer-text mt-2 md:mb-0 mb-4 md:mr-auto md:ml-5 md:py-0 md:pl-4 mr-0 ">

                        <Link to="/" class="md:mr-5 mr-3 text-white" >Home</Link>
                        <Link to="/aboutus" class="md:mr-5 mr-3 text-white" >About us</Link>
                        <Link to="/insights" class="md:mr-5 mr-3  text-white" >Insights</Link>
                        <Link to="/Services" class="md:mr-5 mr-3  text-white" >Services</Link>
                        <Link to="/TandC/" class="md:mr-5 mr-3  text-white">T&C</Link>
                        <Link to="/PrivacyandPolicy/" class="mr-0  text-white" >Privacy & Policy</Link>


                    </nav>
                    <div class="social-container">
                        <ul class="social-icons mt-6">
                            <li><a href="https://www.linkedin.com/company/ubiqlogix/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                            <li><a href="https://www.facebook.com/ubiqlogix/" target="_blank"><i class="fa fa-facebook"></i></a></li>
                        </ul>
                    </div>
                </div>

            </div>
            <div style={{ backgroundColor: '#00B9E8', padding: '6px' }}>
                <h1 class="text-center text-white text-xm">© Copyright 2021. All Rights Reserved.</h1></div>
        </div >
    )
}

export default Footer
