import React, { useState, useEffect } from 'react'
import Snap1 from '../images/techbg.jpg'

import bg1 from '../images/bg1.jpg'
import Snap2 from '../images/insight.jpg'

import Snap3 from '../images/service.jpg'

import Snap4 from '../images/contact.jpg'

import sanityClient from '../client.js'
import { Link } from 'react-router-dom'
import AwesomeSlider from 'react-awesome-slider';

import Slider from 'infinite-react-carousel';
const HomeC = () => {

    const settings = {
        boxCount: 3,
        // adaptiveHeight: true,
        arrows: false,
        arrowsBlock: false,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: false
    };
    return (
        <div>
            <Slider {...settings}>
                <div>
                    <div
                        style={{
                            backgroundImage: `url(${bg1})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',

                        }}
                    >
                        <div class=" lg:flex-grow light herobg w-full md:w-3/5   flex flex-col md:items-start  md:text-left mb-6 md:mb-0 ">

                            <p class="mb-0 mt-40    text-2xl">UbiqLogix</p>
                            <h1 class="w-136 hero-maintext  sm:text-4xl text-2xl mb-10 mt-6   " style={{ lineHeight: '140%' }}>Pioneering Solutions. <br /> Anytime, Everywhere..
                            </h1>
                            <div class="mb-56 flex hero-contactus ">
                                <Link to='/contactus'><button class="inline-flex contactus">Know more</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        style={{
                            backgroundImage: `url(${Snap2}), linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55))`,
                            backgroundBlendMode: 'overlay',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',

                        }}



                    >
                        <div class=" lg:flex-grow light herobg md:w-3/5 w-full flex flex-col md:items-start mt-20 md:text-left mb-16 md:mb-0 ">

                            <p class="mb-0 mt-20 welcome md:ml-0 -ml-28 leading-relaxed text-2xl">Emerging technologies </p>
                            <h1 class="w-136 hero-maintext  sm:text-4xl text-2xl mb-10 mt-6 leading-relaxed  " style={{ lineHeight: '140%', width: '70%', fontWeight: '400' }}>Neural value chains <br /> Modern Supply chain <br /> Distributed Computing <br /> Robot Process Automation
                            </h1>
                            <div class="mb-56 flex hero-contactus ">
                                <Link to='/contactus'><button class="inline-flex contactus">Lets Understand</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        style={{
                            backgroundImage: `url(${Snap3}), linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55))`,
                            backgroundBlendMode: 'overlay',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',

                        }}
                    >
                        <div class=" lg:flex-grow light herobg md:w-3/5 w-full flex flex-col md:items-start mt-20 md:text-left mb-16 md:mb-0 ">

                            <p class="mb-0 mt-20  md:ml-0 -ml-28 welcome leading-relaxed text-xl md:text-2xl">Pioneer Your Digital Strategy</p>
                            <h1 class="w-136 hero-maintext  sm:text-4xl text-2xl mb-10 mt-6 leading-relaxed  " style={{ lineHeight: '140%', width: '70%' }}>Assess your internal limitations before adopting to a new Digital Strategy.
                            </h1>
                            <div class="mb-56 flex hero-contactus ">
                                <Link to='/contactus'><button class="inline-flex contactus">Know more</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default HomeC
