import React from 'react'

import { Link } from 'react-router-dom'
import thank from '../images/thank.svg'
import Nav from './Nav'
import Footer from './Footer'
import { SEO } from './Seo'
const Thanks = () => {
    return (
        <div>
        
        <SEO title="Ubiqlogix | Thank You" description="Ubiqlogix Private limited" />
     
        <div className="container flex flex-col  center " style={{height:'100vh'}}>
        <img src={`${thank}`} className="w-64 mt-36 ml-0 md:ml-40 " alt="Thank you" />
        <h1 className="mt-10 leading-releaxed w-full text-xl mb-10 ml-0 md:ml-16">Thank you for contacting us, we recived your message</h1>
      <Link to="/">  <h1 className="mt-1 leading-releaxed w-full text-xl mb-20 ml-0 md:ml-16">Click here for home page</h1> </Link>
        </div>
       
</div>
    )
}

export default Thanks
