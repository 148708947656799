import React from 'react'

import bg1 from '../images/ab1.jpg'
const ContactForm = () => {
    return (
        <div>
		
        <div class="mt-20 mb-20 container-contact100">
		<div class="wrap-contact100">
			<form class="contact100-form validate-form" action="https://formsubmit.co/sales@ubiqlogix.com" method="POST">
				<span class="contact100-form-title">
					Request for Information
				</span>

				<label class="label-input100" for="first-name">Name *</label>
				<div class="wrap-input100 rs-wrap-input100 validate-input" data-validate="Type first name">
					<input id="first-name" class="input100" type="text" name="first-name" required placeholder="Full name" />
					<span class="focus-input100"></span>
				</div>
				

				<label class="label-input100" for="email">Enter your email *</label>
				<div class="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
					<input id="email" class="input100" type="text" name="email" required placeholder="Eg. example@email.com" />
					<span class="focus-input100"></span>
				</div>

				<label class="label-input100" for="phone">Enter Subject</label>
				<div class="wrap-input100">
					<input id="phone" class="input100" type="text" name="phone" required placeholder="Eg.Services,Insights...etc" />
					<span class="focus-input100"></span>
				</div>

				<label class="label-input100" for="message">Message *</label>
				<div class="wrap-input100 validate-input" data-validate = "Message is required">
					<textarea id="message" class="input100" name="message" required placeholder="Write us a message"></textarea>
					<span class="focus-input100"></span>
				</div>

				<div class="container-contact100-form-btn">
					<button class="contact100-form-btn">
						Send Message
					</button>
				</div>
			</form>

			<div class="contact100-more flex-col-c-m" style={{backgroundImage: `url(${bg1})`}}>
				<div class="flex-w size1 p-b-47">
					<div class="txt1 p-r-25">
					</div>
                    <span className="txt1 md:text-4xl text-2xl mx-auto">
Let's Work Together</span>
					<div class="flex-col size2">
						
					</div>
				</div>

				<div class="dis-flex size1 p-b-47">
					<div class="txt2 mt-6 mx-12 p-r-25">
                    <span>
                    We exist to make technology work more effectively for you. Out top goal is to always provide superior service in a way that everyone can understand no matter their knowledge level.</span>
					</div>

					<div class="flex-col size2">
						
					</div>
				</div>

				<div class="dis-flex size1 p-b-47">
					<div class="txt1 p-r-25">
					</div>

					<div class="flex-col size2">
					
					</div>
				</div>
			</div>
		</div>
	</div>


        </div>
    )
}

export default ContactForm
