import React, { useState } from 'react'
import Footer from './Footer';
import Nav from './Nav';
import search from '../images/search.svg'
import SearchResults from './SearchResults';

const Search = () => {
    const [insight, setInsight] = useState("");
    const inputEvent = (event) => {
        const data = event.target.value;
        setInsight(data)
    }

    function searchList() {
        return (
            <>
                <SearchResults inputData={insight} />
            </>
        );
    }
    return (
        <>
    <Nav /> 
            <div>
            <div className="SearchBar  justify-center flex flex-col  container center" style={{height:'100vh'}} >
            <img  className="search-img"  width='236' src={`${search}`} />
            <h1 className="md:text-2xl text-lg text-center mt-4 mb-2 md:-mb-6" >What are you looking for?</h1>
                <input type="search" placeholder="   Search for Insights" value={insight} onChange={inputEvent} />
                {searchList()}

                
            </div>
            <button className="mx-auto subscribe sbutton">Search</button> </div>
            <Footer />
        </>

    )
}

export default Search
