import React, { useState, useEffect } from 'react'
import sanityClient from '../client.js'
import { Link } from 'react-router-dom'
import Carousel from "react-elastic-carousel";
import Loader from './Loader.js';


const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
];
const Services = () => {
    const [servicesData, setServicesData] = useState(null)
    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "services"]{
            title,
            slug,
            description,
            iconFile
      }`,
            )
            .then((data) => {
                // console.log(data)
                setServicesData(data)
            })
            .catch(console.error)
    }, [])
    if (!servicesData) return <><Loader /></>
    return (
        <div>
            <div data-aos="fade-right" data-aos-duration="1000" className="container mx-auto  mt-10">
                <p className="text-4xl featured-content mb-2 ml-5">Featured Services</p>
                <div className="flex mt-2 mb-12 line justify-start ml-5">
                    <div className="w-36 h-1 rounded-full  inline-flex" style={{ backgroundColor: '#0057FF', }}></div>
                </div>
            </div>
            <Carousel className="container carousell" style={{ margin: 'auto' }} breakPoints={breakPoints}>
                {servicesData && servicesData.map((serviceData, index) => (
                    <>
                        <div data-aos="fade-up" data-aos-duration="1000" className="carousell-cell" style={{ Padding: '30px !important', marginLeft: '20px' }}>
                            <div className="icon"> <span className={`${serviceData.iconFile}`}></span></div>
                            <h1 className="mt-6 " style={{ fontSize: '17px', }}>{serviceData.title}</h1>
                            <p className="mt-2 text-justify" style={{ fontSize: '13px', }}>{serviceData.description}</p>
                            <div className="lbutton flex-end mt-8">
                                <Link to={'/services/' + serviceData.slug.current}> <button className="inline-flex learn-more"> Know more</button></Link>
                            </div>
                        </div>
                    </>
                ))}
            </Carousel>

        </div >
    )
}

export default Services
