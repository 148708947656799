import React from 'react'
import bg1 from '../images/map12.jpg'
import bg2 from '../images/map2.png'
const UMap = () => {
  return (
    <div className="  mt-10 mb-10 container mx-auto">
      <h1 class="sm:text-4xl text-3xl font-medium text-center mb-8 text-black">Office locations</h1>



      <section class="text-gray-900 body-font relative">
        <div class="container px-5 py-0 mx-auto flex sm:flex-nowrap flex-wrap">

          <div class="lg:w-1/3 md:w-1/2  flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
            <h2 class="text-blue-600  text-2xl mb-4 font-medium title-font"> Get in touch</h2>
            <div class="relative mb-4">
              <h1 className="text-lg mb-4 text-blue-500" >       <a href="mailto:contact@ubiqlogix.com">contact@ubiqlogix.com </a>  </h1>
              <div className="flex mb-4 flex-row ">
                <div className="w-1/2">
                  <img class="object-cover" alt="hero" src={`${bg2}`} />
                </div>
                <div>
                  <h1 className="text-lg mb-1 font-medium ">Australia:</h1>
                  <h1 className="text-lg ">208 Pacific Highway, LindField</h1>
                  <h1 className="text-lg mb-3">Sydney, NSW 2070</h1>

                </div>
              </div>
              <h1 className="text-lg mb-1 font-medium ">India: Visakhapatnam | Hyderabad</h1>

            </div>
          </div>
          <div class="lg:w-2/3 md:w-1/2  rounded-lg overflow-hidden ml-0 md:ml-4 p-2 flex items-end justify-start relative">
            <div class=" relative flex flex-wrap py-6 rounded ">
              <img class="object-cover  " alt="hero" src={`${bg1}`} />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default UMap
