import React from 'react'

import Nav from './Nav'
import Footer from './Footer'
import Whatwedo from './Whatwedo'
import { SEO } from './Seo'
const Home = () => {
    return (
        <div>
        
        <SEO title="Ubiqlogix | About us" description="Ubiqlogix Private limited" />
            <Nav />
              <Whatwedo />
            <Footer />
</div>
    )
}

export default Home
