import React, { Fragment, useState, useEffect } from 'react'
import career from '../images/career.jpg'
import careerbg from '../images/careerbg.png'
import carebg from '../images/care.jpg'
import sanityClient from '../client.js'
import { Link } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import Loader from './Loader'
import ReactGA from 'react-ga'
import CareersModal from './CareersModal'
// Import Pagination
import Pagination from "react-sanity-pagination";


const itemsToSend = [];
const CareerContent = () => {
    const ClickHandler = () => {
        ReactGA.event({
            category: 'Button',
            action: 'Clicked on Contactus button'
        })
    }


    const [careersData, setCareersData] = useState(null)
    const postsPerPage = 5;

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "careers"]{
            title,
            experience,
            salary,
            location,
            description,
            contactEmail
      }`,
            )
            .then((data) => {
                // console.log(data)
                itemsToSend.push(...data);
                // setCareersData(data)
            })
            .catch(console.error)
    }, [])

    const action = (page, range, items) => {
        console.log(`📄 Page: ${page}, 🌀 Items: `, items);
        // Update State
        setCareersData(items);
    };

    // if (!careersData) return <><Loader /></>s

    return (
        <div>
            <div data-aos="fade-up" class="landing-section text-white body-font" style={{ marginTop: '0px !important', color: 'white !important', }}>
                <div className="insight-mainbg mx-auto flex px-5 py-10 md:flex-row flex-col items-center" style={{ backgroundImage: `url(${carebg}), linear-gradient(rgba(0, 0, 0, 0.741), rgba(0, 0, 0,0.742))`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                    <div class="ml-auto  md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start mt-10 md:text-left mb-16 md:mb-0 items-center text-center" style={{ marginTop: '12%', }}>
                        <h1 data-aos="fade-up" class=" md:mt-0 mt-36 sm:text-4xl text-4xl mb-2 leading-relaxed font-medium " style={{ color: 'white !important', lineHeight: '140%', }}>Planning a better you ?
                        </h1>
                        <p data-aos="fade-up" class="mb-8 text-2xl leading-relaxed">Welcome to UbiqLogix</p>

                        <div data-aos="fade-up" class="mb-56 flex hero-contactus justify-center">
                            <Link to={'/contactus'} >  <button onClick={ClickHandler} style={{ color: 'white !important' }} class="inline-flex contactus text-xl  ">Contact us</button></Link>
                        </div>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-3/4" style={{ marginRight: '5%' }}>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-delay="200" class="container career-atubiq mx-auto  mt-10">
                <p class="text-4xl  mb-2">Careers at UbiqLogix</p>
                <div class="flex mt-2 mb-6 justify-center">
                    <div class="w-36 h-1 rounded-full   inline-flex" style={{ backgroundColor: '#0057FF', }}></div>
                </div>
                <h1 class="md:text-justify  md:mx-36   mb-16">Planning a better you ?, come, be part of a great team. Bring your ideas to work, lets pioneer solutions together to solve some of the world’s greatest challenges. We give you the right tools, the techniques, and right people to collaborate and build an inspiring tomorrow. We work with exceptional people across the globe to create value for our clients, people, and communities.</h1>
            </div>


            <div class="text-white body-font hiring" style={{ backgroundImage: `url(${careerbg})`, backgroundSize: 'contain' }}>
                <div data-aos="fade-up" data-aos-delay="300" class="flex flex-col were-hiring text-center w-full mb-12 " style={{ paddingTop: '5%' }}>
                    <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2">We're Hiring</h1>
                    <div class="flex mt-2 mb-6 justify-center">
                        <div class="w-36 h-1 rounded-full   inline-flex" style={{ backgroundColor: '#0057FF' }}></div>
                    </div>
                    <p class="text-justify lg:w-2/3 mx-auto leading-relaxed  text-lg">Choose a career with us, and together we will transform world’s leading businesses while sharing knowledge and pushing ourselves to do better.</p>
                </div>
                <div class="container main-job items-center" style={{ overflowY: 'hidden' }}>
                    {careersData && careersData.map((data, index) => (
                        <>
                            <CareersModal data={data} />
                        </>
                    ))}

                    <Pagination
                        nextButton={true}
                        prevButton={true}
                        nextButtonLabel={"➡️"}
                        prevButtonLabel={"⬅️"}
                        items={itemsToSend}
                        action={action}
                        postsPerPage={postsPerPage}
                    />
                </div>
            </div>

        </div>
    )
}



export default CareerContent
