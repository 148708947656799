import React from 'react'
import Nav from './Nav'
import Footer from './Footer'

import { Link } from 'react-router-dom'
import err from '../images/error.png'
const ErrorS = () => {
	return (
		<div style={{ height: '100vh' }}>{/**
        <div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<h1>404</h1>
				<h2>Page not found</h2>
			</div>
			<a href="/">Homepage</a>
		</div>
	</div> */}
			<Nav />
			<div className="container md:w-2/5  mx-auto" >
				<img data-aos-delay="200" data-aos="fade-left" className="pt-20" src={`${err}`} alt="Error" />
				
			</div>
			<div className="container  mx-auto">
			<h1 className="text-center mb-4 text-lg mt-2">We are Pioneering Our Services and Insights to bring you the Best!
					<br/> If you need an assistance, we are Ubiq and here to help.
				</h1>
				<div className='notfound mx-auto'>
					<Link className="mb-10" to="/contactus">Contactus</Link>
				</div></div>
			<Footer />
		</div>
	)
}

export default ErrorS
