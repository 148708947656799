import BlockContent from '@sanity/block-content-to-react'
import { Link } from 'react-router-dom'
import imageUrlBuilder from '@sanity/image-url'
import sanityClient from '../client.js'
import err from '../images/error.png'
const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
    return builder.image(source)
}
const ContentInfo = ({ services }) => {
    if (services.imagesContent) {
        return (
            <div>
                <div data-aos="fade-up" class="landing-section text-white body-font" style={{ marginTop: '0px !important', color: 'white !important' }}>
                    <div class=" content-mainbg  flex px-5 py-36  flex-col items-center" style={{ backgroundImage: `url(${services.bannerImage.asset.url}),linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0,0.6))`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                        <div class="text-white body-font">
                            <div class="container px-5 py-24 mx-auto">
                                <div class="flex flex-col text-center w-full mb-12">
                                    <h1 class="sm:text-4xl text-2xxl font-medium title-font mb-4 ">{services.title}</h1>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">

                        <div class="content-text text-gray-900 body-font ">
                            {services.imagesContent && services.imagesContent.map((image, index) => {
                                if (index % 2 === 0) {
                                    return (
                                        <>
                                            <div className="container first-text mx-auto flex px-5 py-8 md:flex-row md:gap-20 gap-0 flex-col items-center" key={index}>
                                                <div data-aos="fade-right" className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 ">

                                                    <p class="mb-8 text-justify leading-relaxed">{services.textContent[index]}</p>


                                                </div>
                                                <div  data-aos="fade-up" class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                                                    <img class="object-cover   content-img" alt="hero" src={urlFor(image).url()} />
                                                </div>
                                            </div>
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <div className="container first-text mx-auto flex px-5 py-8 md:flex-row-reverse gap-0 md:gap-20 flex-col items-center" key={index}>
                                                <div data-aos="fade-up" className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 ">

                                                    <p class="mb-8 text-justify leading-relaxed">{services.textContent[index]}</p>

                                                </div>
                                                <div data-aos="fade-up" class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                                                    <img class="object-cover rounded content-img" alt="hero" src={urlFor(image).url()} />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }

                            })}
                        </div>


                    </div>
                </section>
            </div>
        )
    } else {
        return (
            <div >
                <div data-aos="fade-up" class="landing-section text-white body-font" style={{ marginTop: '0px !important', color: 'white !important' }}>
                    <div class=" content-mainbg  flex px-5 py-36  flex-col items-center" style={{ backgroundImage: `url(${services.bannerImage.asset.url}), linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0,0.6))`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                        <div class="text-white body-font">
                            <div class="container px-5 py-24 mx-auto">
                                <div class="flex flex-col text-center w-full mb-12">
                                    <h1 class="sm:text-6xl text-2xl font-medium title-font mb-4 ">{services.title}</h1>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container md:w-2/5  mx-auto" >
                    <img data-aos-delay="200" data-aos="fade-left" className="pt-20" src={`${err}`} alt="Error" />

                </div>
                <div className="container  mx-auto">
                    <h1 className="text-center mb-4 text-lg mt-2">We are Pioneering Our Services and Insights to bring you the Best!
                        <br /> If you need an assistance, we are Ubiq and here to help.
                    </h1>
                    <div className='notfound mx-auto'>
                        <Link className="mb-10" to="/contactus">Contactus</Link>
                    </div></div>
            </div>
        )

    }
}

export default ContentInfo
