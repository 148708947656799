import React from 'react'
import Nav from './Nav'
import Footer from './Footer'
import b1 from '../images/career.jpg'
import ContactFrom from './ContactForm'
const ServiceTemp = () => {
    return (

        <div>
<Nav />
        <div data-aos="fade-up" class="landing-section text-white body-font" style={{ marginTop: '0px !important', color: 'white !important' }}>
        <div class=" content-mainbg  flex px-5 py-36  flex-col items-center" style={{ backgroundImage: `url(${b1}), linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0,0.6))`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
            <div class="text-white body-font">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-col text-center w-full mb-12">
                        <h1 class="sm:text-6xl text-2xl font-medium title-font mb-4 ">Title </h1>
                    </div>

                </div>
            </div>
        </div>
        <div className="container text-black mt-10 first-text mx-auto flex px-5 py-8 md:flex-row md:gap-20 gap-0 flex-col items-center" >
        <h1 className="text-xl mx-36">We are Pioneering Our Services and Insights to bring you the Best! If you need an assistance, we are Ubiq and here to help.</h1>
                                    
                                </div>
                             
    </div>
    <ContactFrom />
    <Footer />
        </div>
    )
}

export default ServiceTemp
