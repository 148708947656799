import React, { useState, useEffect } from 'react'
import sanityClient from '../client.js'
import { Link } from 'react-router-dom'
import Nav from './Nav'
import Footer from './Footer'
import ContentInfo from './ContentInfo'
import Loader from './Loader.js'


const SearchResults = ({ inputData }) => {

    const [insightsData, setInsightsData] = useState(null)
    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "insights" && title == "${inputData}" ]{
            title,
            slug,
            description,
            mainImage{
               asset->{
                   _id,
                   url
               },
               alt
           },
           bannerImage{
            asset->{
                _id,
                url
            },
            alt
        },
        blockContent
      }`,
            )
            .then((data) => {
                // console.log("data :" + data[0])
                setInsightsData(data)
            })
            .catch(console.error)
    }, [inputData])
    if (!insightsData) return <><Loader /></>
    return (
        <div>
            <div class="content-text text-gray-900 body-font ">
                {insightsData && insightsData.map((insight, index) => {
                    if (index % 2 === 0) {
                        return (
                            <>
                                <div className="container first-text mx-auto flex px-5 py-8 md:flex-row md:gap-20 gap-0 flex-col items-center" key={index}>
                                    <div data-aos="fade-right" className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 ">
                                        <h1 class=" sm:text-3xl text-2xl mb-2  font-medium ">{insight.title}
                                        </h1>
                                        <p class="mb-8 leading-relaxed">{insight.description}</p>
                                        <div class="lbutton flex-end  mt-0">
                                            <Link to={'/insights/' + insight.slug.current}> <button class="inline-flex learn-more"> Learn more</button> </Link>
                                        </div>

                                    </div>
                                    <div data-aos="fade-left" class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                                        <img class="object-cover rounded content-img" alt="hero" src={insight.mainImage.asset.url} />
                                    </div>
                                </div>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <div className="container first-text mx-auto flex px-5 py-8 md:flex-row-reverse gap-0 md:gap-20 flex-col items-center" key={index}>
                                    <div data-aos="fade-right" className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 ">
                                        <h1 class=" sm:text-3xl text-2xl mb-2  font-medium ">{insight.title}
                                        </h1>
                                        <p class="mb-8 leading-relaxed">{insight.description}</p>
                                        <div class="lbutton flex-end  mt-0">
                                            <Link to={'/insights/' + insight.slug.current}> <button class="inline-flex learn-more"> Learn more</button> </Link>
                                        </div>

                                    </div>
                                    <div data-aos="fade-left" class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                                        <img class="object-cover rounded content-img" alt="hero" src={insight.mainImage.asset.url} />
                                    </div>
                                </div>
                            </>
                        )
                    }

                })}
            </div>
        </div>
    )
}

export default SearchResults
