import React, { useState, useEffect } from 'react'
import c1 from '../images/UbiqAcademy.png'
import c2 from '../images/ubiqally.png'
import c3 from '../images/c3.jpeg'
import c4 from '../images/c2.png'
import c5 from '../images/c5.jpeg'
import contact from '../images/contactus.jpg'
import sanityClient from '../client.js'
import { Link } from 'react-router-dom'
import  Loader  from './Loader'
const ContactusContent = () => {
    const [contactData, setContactData] = useState(null)

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "contactInfo"]{
            title,
            phoneNumber,
            emails,
            address
      }`,
            )
            .then((data) => {
                // console.log(data)
                setContactData(data[0])
            })
            .catch(console.error)
    }, [])
    if (!contactData) return <><Loader /></>

    return (
        <div>
            <section style={{ backgroundColor: '#FFF', }}>
                <h1 data-aos="fade-up" data-aos-delay="200" className="text-center text-3xl md:mt-0 mt-16  md:text-4xl text-black pt-6">Customers we served</h1>
             
                <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto">
                 
                  <div class="flex flex-wrap -m-4">
                    <div class="p-4 lg:w-1/5 md:w-1/2">
                      <div class="h-full flex flex-col items-center text-center">
                        <img alt="team" class="flex-shrink-0 rounded-lg md:w-42 md:h-28 object-fit object-center mb-4" src={`${c1}`} / >
                   
                      </div>
                    </div>
                    <div class="p-4 lg:w-1/5 md:w-1/2">
                      <div class="h-full flex flex-col items-center text-center">
                        <img alt="team" class="flex-shrink-0 rounded-lg w-40 md:ml-0 ml-20 md:w-28 md:h-28 object-fit object-center mb-4" src={`${c2}`} />
                   
                      </div>
                    </div>
                    <div class="p-4 lg:w-1/5 md:w-1/2">
                      <div class="h-full flex flex-col items-center text-center">
                        <img alt="team" class="flex-shrink-0 rounded-lg w-36 h-16 md:w-40 md:ml-0 ml-20 md:h-18 md:mt-8 object-fit object-center mb-4" src={`${c3}`} />
                      
                      </div>
                    </div>
                    <div class="p-4 lg:w-1/5 md:w-1/2">
                      <div class="h-full flex flex-col items-center text-center">
                        <img alt="team" class="flex-shrink-0 rounded-lg w-56 ml-16 md:ml-0 md:w-48 md:h-28 object-fit object-center mb-4" src={`${c4}`} />
                   
                      </div>
                    </div>
                    <div class="p-4 lg:w-1/5 md:w-1/2">
                      <div class="h-full flex flex-col items-center text-center">
                        <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-36 ml-16 md:ml-0 md:w-36 md:h-28 object-fit object-center mb-4" src={`${c5}`} />
                   
                      </div>
                    </div>
                  </div>
                </div>
              </section>
             
                {/* <div data-aos="fade-up" data-aos-delay="300" className="wraper">

                    <div className="slider">
                        <div className="slide">
                        
                        <img src={`${c1}`} />
                        <img src={`${c2}`} />
                        <img  src={`${c3}`} className="c3image"/>
                        <img src={`${c4}`} />
                        <img src={`${c5}`} />
                        </div>
                    </div>
                </div>*/}

            </section>
{/*
            <div id="testi" className="text-center mt-10">
                <div data-aos="fade-up">
                    <p className="text-4xl featured-content text-black mb-2 ">Customer Stories</p>
                    <div className="flex mt-2 mb-0 justify-center ">
                        <div className="h-1 rounded-full w-36 inline-flex" style={{ backgroundColor: '#0057FF' }}></div>
                    </div>
                </div>
            </div>
            <section className="testim" id="testim">

                <div data-aos="fade-right" className="wrap">

                    <span id="right-arrow" className="arrow right fa fa-chevron-right"></span>
                    <span id="left-arrow" className="arrow left fa fa-chevron-left"></span>

                    <ul className="dots" id="testim-dots">
                        <li className="dot active"></li>
                        <li className="dot"></li>
                        <li className="dot"></li>
                    </ul>

                    <div className="cont" id="testim-content">

                        <div className="active">
                            <div className="img"></div>
                            <h2 className="person">Greg Craddock <span className="profession">-President, Craddock Enterprises LLC</span> </h2>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                        </div>

                        <div>
                            <div className="img"></div>
                            <h2 className="person">Alicia Tomlin <span className="profession">-Vice President, Gilmon Fox General Contractors</span> </h2>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                        </div>

                        <div>
                            <div className="img"></div>
                            <h2 className="person">Timothy Kross <span className="profession">-Operations Manager, Cavalier Engineering</span> </h2>
                            <p> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                        </div>


                    </div>

                </div>
            </section>
*/}

        </div>
    )
}

export default ContactusContent
