import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './components/Home'
import Nav from './components/Nav'
import { useEffect } from 'react';
import $ from "jquery";
import Footer from './components/Footer'
import Error from './components/Error'
import Aboutus from './components/Aboutus'
import Insights from './components/InsightsPage'
import Services from './components/ServicesPage';
import Career from './components/CareerPage'
import Contactus from './components/ContactusPage'
import Content from './components/Content'
import Search from './components/Search'
import ErrorS from './components/ErrorS';
import AOS from 'aos'
import 'aos/dist/aos.css'
import ContentServices from './components/ContentServices';
import Thanks from './components/Thanks';
import ReactGA from 'react-ga';
import Template from './components/ServiceTemp'
import TandC from './components/TandC';
import PrivacyandPolicy from './components/PrivacyandPolicy';
import ScrollToTop from "./ScrollToTop";

function App() {

  useEffect(() => {
    ReactGA.initialize('G-8BKDVVNYZN')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])
  AOS.init({ duration: 3000 })
  return (
    <BrowserRouter >
      <ScrollToTop>
        <Switch>
          {/* <Nav /> */}
          <Route component={Home} path='/' exact />
          <Route exact component={Aboutus} path='/aboutus' />
          <Route component={Content} path='/insights/:slug' exact />
          <Route component={Insights} path='/insights' exact />
          <Route component={ContentServices} path='/services/:slug' />
          <Route component={Services} path='/services' exact />
          <Route component={Career} path='/careers' exact />
          <Route component={Contactus} path='/contactus' exact />
          <Route component={Search} path='/search' exact />
          <Route component={Template} path='/template' exact />
          <Route component={TandC} path='/tandc' exact />
          <Route component={PrivacyandPolicy} path='/PrivacyandPolicy' exact />
          <Route component={ErrorS} path='/notfound' exact />
          <Route component={Thanks} path='/thanks' exact />
          {/* <Route component={Content} path='/content' exact /> */}
          <Route component={Error} />
          {/* <Footer /> */}
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
