import React, { useState, useEffect } from 'react'
import sanityClient from '../client.js'
import ai from '../images/Ai.jpg'
import cloud from '../images/cloud.jpg'
import marketing from '../images/digitalM.jpg'
import { Link } from 'react-router-dom'
import Loader from './Loader.js'
import Pagination from "react-sanity-pagination";

const itemsToSend = [];
const FeaturedContent = ({ featured, insights }) => {
    const [insightsData, setInsightsData] = useState(null)
    const [index1, setIndex1] = useState(0)
    useEffect(() => {

        if (featured) {
            sanityClient
                .fetch(
                    `*[_type == "insights" && featured == true]{
            title,
            slug,
            description,
            mainImage{
               asset->{
                   _id,
                   url
               },
               alt
           },
           featured
      }`,
                )
                .then((data) => {
                    // console.log(data)
                    setInsightsData(data)
                    // itemsToSend.push(...data);

                })
                .catch(console.error)
        } else {
            sanityClient
                .fetch(
                    `*[_type == "insights"]{
        title,
        slug,
        description,
        mainImage{
           asset->{
               _id,
               url
           },
           alt
       },
       featured
  }`,
                )
                .then((data) => {
                    // console.log(data)
                    setInsightsData(data)
                    // itemsToSend.push(...data);

                })
                .catch(console.error)
        }

    }, [])

    const action = (page, range, items) => {
        console.log(`📄 Page: ${page}, 🌀 Items: `, items);
        // Update State
        setInsightsData(items);
    };
    if (!insightsData) return <><Loader /></>


    return (


        <div>
            <div data-aos="fade-up" id="content" class="container md:mb-0 -mb-10 showcase mx-auto  mt-10">
                <p class="text-4xl featured-content mb-2 ml-5" style={{ fontWeight: '500' }}>Featured Insights</p>
                <div class="flex mt-2 mb-12 ml-5 justify-start">
                    <div class="h-1 rounded-full w-36 inline-flex" style={{ backgroundColor: '#0057FF', }}></div>
                </div>
            </div>



            <div class="content-text text-gray-900 body-font ">
                {insightsData && insightsData.map((insight, index) => {
                    if (index % 2 === 0) {
                        return (
                            <>
                                <div className="container first-text mx-auto  flex px-5 py-8 md:flex-row md:gap-20 gap-1 flex-col items-left" key={index}>
                                    <div data-aos="fade-right" className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 ">
                                        <h1 class=" sm:text-3xl text-2xl mb-2  font-medium ">{insight.title}
                                        </h1>
                                        <p class="mb-8 text-justify leading-relaxed">{insight.description}</p>
                                        <div class="rbutton flex-end   mt-0">
                                            <Link to={'/insights/' + insight.slug.current}> <button class="inline-flex learn-more"> Read more</button> </Link>
                                        </div>

                                    </div>
                                    <div data-aos="fade-left" class="md:mt-0 mt-4 md:ml-0  lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                                        <img class="object-cover rounded  content-img" alt="hero" src={insight.mainImage.asset.url} />
                                    </div>
                                </div>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <div className="container first-text mx-auto flex px-5 py-8 md:flex-row-reverse gap-1 md:gap-20 flex-col items-start" key={index}>
                                    <div data-aos="fade-right" className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 ">
                                        <h1 class=" sm:text-3xl text-2xl mb-2  font-medium ">{insight.title}
                                        </h1>
                                        <p class="mb-8 text-justify leading-relaxed">{insight.description}</p>
                                        <div class="rbutton flex-end  mt-0">
                                            <Link to={'/insights/' + insight.slug.current}> <button class="inline-flex learn-more"> Read more</button> </Link>
                                        </div>

                                    </div>
                                    <div data-aos="fade-left" class="md:mt-0 mt-4 md:ml-0  lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                                        <img class="object-cover rounded content-img" alt="hero" src={insight.mainImage.asset.url} />
                                    </div>
                                </div>
                            </>
                        )
                    }

                })}
            </div>
        </div>
    )

}

export default FeaturedContent
