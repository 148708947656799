import React from 'react'
import contact from '../images/contact.jpg'

import cont from '../images/cont.jpg'

import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
const ContactusContent = () => {
    const ClickHandler=()=>{
        ReactGA.event({
            category:'Button',
            action:'Clicked on Contactus button'
        })
    }
    


    return (
        <div>
        <section style={{ marginTop: "40px !important" }}>
        <div data-aos="fade-up" data-aos-duration="2000" class=" hero-mainbg  mx-auto flex px-5 py-10 md:flex-row flex-col items-center" style={{backgroundImage: `url(${cont}), linear-gradient(rgba(0, 0, 0, 0.441), rgba(0, 0, 0,0.442))`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',backgroundBlendMode:'overlay'}}>
            <div class=" text-white ml-auto container  md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start mt-10 md:text-left mb-16 md:mb-0 ">
            <h1 class="hero-maintext  sm:text-4xl text-3xl mt-28 leading-relaxed font-medium " style={{ lineHeight: "140%" }}>We Transform Your Digital
                    <br class="hidden lg:inline-block" />Experience Into Realities </h1>    
            <p class=" md:mt-2 mt-8  mb-6  ">Welcome to UbiqLogix</p>
            <div class="mb-56 flex hero-contactus ">
            <a href="#connect"><button  class="inline-flex contactus">Contact us</button></a>
        </div>
               
            </div>
            <div class="lg:max-w-lg lg:w-full md:w-1/2 w-3/4" style={{marginRight:'5%',}}>
            </div>
        </div>
    </section>
    
        </div>
    )
}

export default ContactusContent
