import React from 'react'

import HomeCarousal from './HomeCarousal'
import Nav from './Nav'
import Services from './Services'
import FeaturedContent from './FeaturedContent'
import Footer from './Footer'
import { SEO } from './Seo'
import HomeC from './HomeC'
import Banner from './Banner'
const Home = () => {
    return (
        <div>

            <SEO title="Ubiqlogix" description="UbiqLogix is an IT services and solutions company with leading capabilities in digital, cloud and security
            services. We offer Technology and Services, Strategy, Consulting and Innovation. We pioneer solutions
            to address the entire breadth of clients business needs, from strategy and design to operations, fuelled
            by the fast evolving and innovative world of cloud, data, AI, connectivity, software, digital engineering
            and platforms and deliver unprecedented levels of performance and customer delight." />

            <Nav />
            <HomeC />
            {/* <HomeCarousal /> */}
            <Services />
            <FeaturedContent featured={true} />
            <Footer />
        </div>
    )
}

export default Home
